import { useEffect, useState } from "react";
import { IconBox } from "../IconBox";
import { css, useTheme } from "@emotion/react";
import axios from "axios";
import cookie from "cookie";
import dayjs from "dayjs";
import { zIndex } from "styles/zIndex";

const link = "https://support.mix.day/dee2c9ae-495a-4672-8aa2-3fcc9d5feb77";

export const TopBanner = ({
  text,
  url,
  endDate,
}: {
  text: string | undefined;
  url: string | undefined;
  endDate: string | undefined;
}) => {
  const theme = useTheme();

  const [isTopBannerOpen, setIsTopBannerOpen] = useState(false);

  useEffect(() => {
    const key = "mix/topbanner";
    const cookies = document.cookie;
    const exposed = cookie.parse(cookies)[key] === "true";

    const inRange = dayjs().isBefore(endDate);

    const isNotExtension = process.env.BUILD_TARGET !== "extension";

    if (!exposed && inRange && isNotExtension) {
      setIsTopBannerOpen(true);
    }
  }, [endDate]);

  const handleCloseButtonClick = () => {
    axios.post("/api/hide_topbanner", {
      interval: 1,
    });
    setIsTopBannerOpen(false);
  };

  return isTopBannerOpen ? (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        items-align: center;
        background-color: #343862;
      `}
    >
      <a
        href={url || link}
        target="_blank"
        rel="noreferrer"
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 64px;
          background-color: #343862;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 580px;
          z-index: ${zIndex.header};

          @media (max-width: 768px) {
            height: 70px;
            background-size: 280px;
          }
        `}
        dangerouslySetInnerHTML={{ __html: text || "" }}
      />
      <IconBox
        css={css`
          margin: 16px 12px;
          padding: 2px;
          cursor: pointer;
        `}
        name="Close"
        size="xl"
        color={theme.palette.grey[400]}
        data-close-icon
        onClick={handleCloseButtonClick}
      />
    </div>
  ) : (
    <></>
  );
};
