// Add data types to window.navigator for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />
import {
  ClassAttributes,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import Image from "next/image";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import { IconBox } from "components/core/IconBox";
import { Text } from "components/core/Text";
import { Button } from "components/core/buttons/Button";
import { AppContext } from "contexts/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import { EXTENSION_STORE_URLS } from "utils/enum";

export default function ChromeBanner({
  ...props
}: ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
  }) {
  const theme = useTheme();
  const { browserName, deviceType } = useContext(AppContext);

  const isChromiumBased =
    typeof navigator !== "undefined" &&
    !!navigator.userAgentData &&
    navigator.userAgentData.brands.some((data) => data.brand === "Chromium");

  const isChromePC = isChromiumBased && deviceType === "desktop";

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 44px 0 44px;
      `}
      {...props}
    >
      <Text
        css={css`
          font-size: 36px;
          font-style: normal;
          font-weight: 300;
          line-height: 130%; /* 46.8px */
        `}
        color="common.black.main"
        variant="h1"
        tag="h1"
      >
        마케터에게 필요한 모든 인사이트를 한 곳에
      </Text>
      <Text color="text.disabled" variant="body1" tag="p">
        믹스에서 최신 트렌드와 마케팅 인사이트를 발견하세요.
      </Text>
      {isChromePC && (
        <div
          css={css`
            display: flex;
            gap: 12px;
            margin-top: 32px;
          `}
        >
          <BannerLogoAnimation />
          <Button
            css={css`
              padding: 12px 16px;
              border-radius: 16px;
              height: 48px;
            `}
            variant="filled"
            color="secondary"
            size="xl"
            onClick={() => {
              window.open(
                EXTENSION_STORE_URLS[browserName.toUpperCase()]?.url,
                "_blank",
              );
            }}
          >
            <IconBox size="medium" name="Add" />
            <Text
              css={css`
                height: 20px;
              `}
              variant="subtitle2"
            >
              {browserName}에 추가
            </Text>
          </Button>
        </div>
      )}
    </div>
  );
}

const BannerLogoAnimation = () => {
  const [logoIndex, setLogoIndex] = useState(0);

  const logos: string[] = [
    "/img/logo_animation_1.png", // 브런치
    "/img/logo_animation_2.png", // 미디엄
    "/img/logo_animation_3.png", // 유튜브
    "/img/logo_animation_4.png", // Think with google
    "/img/logo_animation_5.png", // 네이버 블로그
    "/img/logo_animation_6.png", // 메일리
  ];

  const INTERVAL_TIME = 1305;
  useEffect(() => {
    const timer = setInterval(() => {
      setLogoIndex((prev) => (prev + 1) % logos.length);
    }, INTERVAL_TIME);

    return () => clearInterval(timer);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={logoIndex}
        initial={{ opacity: 0, x: -15, scale: 0.5 }}
        animate={{ opacity: 1, x: 0, scale: 1, transition: { duration: 0.3 } }}
        exit={{ opacity: 0, x: 20, scale: 0.1, transition: { duration: 0.25 } }}
        transition={{ delay: 0.35 }}
      >
        <Image
          css={css`
            border-radius: 16px;
            width: 48px;
            height: 48px;
          `}
          src={logos[logoIndex]!}
          alt="logo"
          width={48}
          height={48}
        />
      </motion.div>
    </AnimatePresence>
  );
};
