import {
  Mix_Content_Tag_Enum_Enum,
  Order_Status_Enum,
} from "graphql/generated";

export const ORDERED_MIX_CONENT_TAG_ENUM_OBJECT: {
  [key in Mix_Content_Tag_Enum_Enum]: Mix_Content_Tag_Enum_Enum;
} = {
  [Mix_Content_Tag_Enum_Enum.MarketTrend]:
    Mix_Content_Tag_Enum_Enum.MarketTrend,
  [Mix_Content_Tag_Enum_Enum.NormalMarketing]:
    Mix_Content_Tag_Enum_Enum.NormalMarketing,
  [Mix_Content_Tag_Enum_Enum.BrandMarketing]:
    Mix_Content_Tag_Enum_Enum.BrandMarketing,
  [Mix_Content_Tag_Enum_Enum.ContentMarketing]:
    Mix_Content_Tag_Enum_Enum.ContentMarketing,
  [Mix_Content_Tag_Enum_Enum.AffiliateMarketing]:
    Mix_Content_Tag_Enum_Enum.AffiliateMarketing,
  [Mix_Content_Tag_Enum_Enum.GrowthHacking]:
    Mix_Content_Tag_Enum_Enum.GrowthHacking,
  [Mix_Content_Tag_Enum_Enum.CrmMarketing]:
    Mix_Content_Tag_Enum_Enum.CrmMarketing,
  [Mix_Content_Tag_Enum_Enum.PerformanceMarketing]:
    Mix_Content_Tag_Enum_Enum.PerformanceMarketing,
  [Mix_Content_Tag_Enum_Enum.Seo]: Mix_Content_Tag_Enum_Enum.Seo,
  [Mix_Content_Tag_Enum_Enum.Career]: Mix_Content_Tag_Enum_Enum.Career,
  [Mix_Content_Tag_Enum_Enum.Business]: Mix_Content_Tag_Enum_Enum.Business,
  [Mix_Content_Tag_Enum_Enum.AiTrend]: Mix_Content_Tag_Enum_Enum.AiTrend,
  [Mix_Content_Tag_Enum_Enum.ConsumerPsy]: Mix_Content_Tag_Enum_Enum.ConsumerPsy,
  [Mix_Content_Tag_Enum_Enum.CultureArts]: Mix_Content_Tag_Enum_Enum.CultureArts,
  [Mix_Content_Tag_Enum_Enum.B2bMarketing]: Mix_Content_Tag_Enum_Enum.B2bMarketing,
};

export const MIX_CONTENT_TAG_ENUM_KOREAN: {
  [key in Mix_Content_Tag_Enum_Enum]: string;
} = {
  [Mix_Content_Tag_Enum_Enum.MarketTrend]: "마켓트렌드",
  [Mix_Content_Tag_Enum_Enum.NormalMarketing]: "일반마케팅",
  [Mix_Content_Tag_Enum_Enum.BrandMarketing]: "브랜드마케팅",
  [Mix_Content_Tag_Enum_Enum.ContentMarketing]: "콘텐츠마케팅",
  [Mix_Content_Tag_Enum_Enum.AffiliateMarketing]: "제휴마케팅",
  [Mix_Content_Tag_Enum_Enum.GrowthHacking]: "그로스해킹",
  [Mix_Content_Tag_Enum_Enum.CrmMarketing]: "CRM마케팅",
  [Mix_Content_Tag_Enum_Enum.PerformanceMarketing]: "퍼포먼스마케팅",
  [Mix_Content_Tag_Enum_Enum.Seo]: "SEO",
  [Mix_Content_Tag_Enum_Enum.Career]: "커리어",
  [Mix_Content_Tag_Enum_Enum.Business]: "비즈니스",
  [Mix_Content_Tag_Enum_Enum.AiTrend]: "AI트렌드",
  [Mix_Content_Tag_Enum_Enum.ConsumerPsy]: "소비자심리",
  [Mix_Content_Tag_Enum_Enum.CultureArts]: "문화예술",
  [Mix_Content_Tag_Enum_Enum.B2bMarketing]: "B2B마케팅",
};

export const ORDERED_MIX_CONTENT_TAG_ENUM = Object.values(
  ORDERED_MIX_CONENT_TAG_ENUM_OBJECT,
);

export enum Position {
  Brand = "브랜드 마케터",
  Performance = "퍼포먼스 마케터",
  Contents = "컨텐츠 마케터",
  CRM = "CRM 마케터",
  Growth = "그로스 해커",
  Etc = "기타",
}

export const ORDERED_POSITION_OBJECT: {
  [key in Position]: Position;
} = {
  [Position.Brand]: Position.Brand,
  [Position.Performance]: Position.Performance,
  [Position.Contents]: Position.Contents,
  [Position.CRM]: Position.CRM,
  [Position.Growth]: Position.Growth,
  [Position.Etc]: Position.Etc,
};

export const ORDERED_POSITION = Object.values(ORDERED_POSITION_OBJECT);

export const POSITION_KOREAN: {
  [key in Position]: string;
} = {
  [Position.Brand]: "브랜드 마케터",
  [Position.Performance]: "퍼포먼스 마케터",
  [Position.Contents]: "컨텐츠 마케터",
  [Position.CRM]: "CRM 마케터",
  [Position.Growth]: "그로스 해커",
  [Position.Etc]: "기타",
};

export const ORDERED_CAREERS = [
  "취준생",
  "신입",
  "1-2년",
  "3-5년",
  "6-10년",
  "10년 이상",
];

export const ORDER_STATUS_ENUM_KOREAN: {
  [key in Order_Status_Enum]: string;
} = {
  [Order_Status_Enum.Ready]: "미결제",
  [Order_Status_Enum.InProgress]: "승인 대기",
  [Order_Status_Enum.WaitingForDeposit]: "입금 대기",
  [Order_Status_Enum.Done]: "결제 완료",
  [Order_Status_Enum.Canceled]: "취소 완료",
  [Order_Status_Enum.WaitingForCanceled]: "취소 대기",
  [Order_Status_Enum.PartialCanceled]: "부분 취소",
  [Order_Status_Enum.Aborted]: "승인 실패",
  [Order_Status_Enum.Expired]: "시간 만료",
};

export const COURSE_STATUS_ENUM_KOREAN: {
  // TODO: 임시 타입
  [key in string]: string;
} = {
  "not-started": "학습 전",
  "in-progress": "학습 중",
  completed: "학습 완료",
};

export enum DEVICE_TYPE {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export const GOOGLE_APPS: {
  [key: string]: { url: string; imgSrc: string };
} = {
  계정: {
    url: "https://myaccount.google.com/",
    imgSrc: "google-my-account.png",
  },
  Gmail: {
    url: "https://mail.google.com/mail/?authuser=0",
    imgSrc: "google-gmail.png",
  },
  드라이브: {
    url: "https://drive.google.com/?authuser=0",
    imgSrc: "google-drive.png",
  },
  Docs: {
    url: "https://docs.google.com/document/?usp=docs_ald&authuser=0",
    imgSrc: "google-docs.png",
  },
  Sheets: {
    url: "https://docs.google.com/spreadsheets/?usp=sheets_ald&authuser=0",
    imgSrc: "google-sheets.png",
  },
  Slides: {
    url: "https://docs.google.com/presentation/?usp=slides_ald&authuser=0",
    imgSrc: "google-slides.png",
  },
  Calendar: {
    url: "https://calendar.google.com/calendar?authuser=0",
    imgSrc: "google-calendar.png",
  },
  채팅: {
    url: "https://chat.google.com?authuser=0",
    imgSrc: "google-chat.png",
  },
  Meet: {
    url: "https://meet.google.com?hs=197&authuser=0",
    imgSrc: "google-meet.png",
  },
  Forms: {
    url: "https://docs.google.com/forms/?authuser=0",
    imgSrc: "google-forms.png",
  },
  "사이트 도구": {
    url: "https://sites.google.com?authuser=0",
    imgSrc: "google-sites.png",
  },
  주소록: {
    url: "https://contacts.google.com/?authuser=0",
    imgSrc: "google-contacts.png",
  },
  Gemini: {
    url: "https://gemini.google.com?authuser=0",
    imgSrc: "google-gemini.png",
  },
  그룹스: {
    url: "https://groups.google.com/?authuser=0",
    imgSrc: "google-groups.png",
  },
  Youtube: {
    url: "https://www.youtube.com?authuser=0",
    imgSrc: "google-youtube.png",
  },
  지도: {
    url: "https://maps.google.com/?authuser=0",
    imgSrc: "google-maps.png",
  },
  뉴스: {
    url: "https://news.google.com?authuser=0",
    imgSrc: "google-news.png",
  },
  "Google Ads": {
    url: "https://ads.google.com/home/?subid=ww-ww-xs-ip-awhc-a-ogb_dash!o2&authuser=0",
    imgSrc: "google-ads.png",
  },
  사진: {
    url: "https://photos.google.com/?authuser=0",
    imgSrc: "google-photos.png",
  },
  번역: {
    url: "https://translate.google.com/?authuser=0",
    imgSrc: "google-translate.png",
  },
  Vault: {
    url: "https://vault.google.com/?authuser=0",
    imgSrc: "google-vault.png",
  },
  Keep: {
    url: "https://keep.google.com?authuser=0",
    imgSrc: "google-keep.png",
  },
  어스: {
    url: "https://earth.google.com/web/?authuser=0",
    imgSrc: "google-earth.png",
  },
  여행: {
    url: "https://www.google.com/travel/?dest_src=al&authuser=0",
    imgSrc: "google-travel.png",
  },
  비밀번호: {
    url: "https://passwords.google.com/?utm_source=OGB&utm_medium=AL&authuser=0",
    imgSrc: "google-passwords.png",
  },
  Merchant: {
    url: "https://merchants.google.com/?authuser=0",
    imgSrc: "google-merchant.png",
  },
  애널리틱스: {
    url: "https://analytics.google.com/analytics/web?utm_source=OGB&utm_medium=app&authuser=0",
    imgSrc: "google-analytics.png",
  },
  Classroom: {
    url: "https://classroom.google.com/",
    imgSrc: "google-classroom.png",
  },
};

export const EXTENSION_STORE_URLS: {
  [key: string]: { url: string };
} = {
  CHROME: {
    url: "https://chromewebstore.google.com/detail/%EB%AF%B9%EC%8A%A4mix-%EB%A7%88%EC%BC%80%ED%8C%85-%EC%9D%B8%EC%82%AC%EC%9D%B4%ED%8A%B8-%EB%AF%B8%EB%94%94%EC%96%B4/lmbbnpelmllcplpeelhghdbjjgcdickc",
  },
  WHALE: {
    url: "https://store.whale.naver.com/detail/mkhcdbjplmcfialkdfboeaplnanchdmd",
  },
  EDGE: {
    url: "https://microsoftedge.microsoft.com/addons/detail/obljmbkddlfbaafiofkmdfdkiodbkeid",
  },
};
